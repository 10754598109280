import React, { useCallback, useEffect, useState } from 'react';
import { deleteAttemptAPI, extendAttemptDeadlineAPI, fetchAllAttemptsAPI, fetchAttemptsByPartnerIdAPI } from '../../apis'; // Ensure you have this API endpoint available
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import './AllAttempt.css'; // Import your CSS file
import { statusMap, timeFormat, typeMap } from '../../utils/helper';
import {  message, Modal, Select, Table } from 'antd';
import { DateTime } from "luxon"; 
import { useDispatch, useSelector } from 'react-redux';
import { ROLE } from '../../utils/constants';
import { resetMock } from '../../redux/slice/mockSlice';
const { Option } = Select;

const AllAttempt = () => {
  const [attemptList, setAttemptList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState('all');
  const navigate = useNavigate();
  const roles = useSelector(state => state.user.roles);
  const partnerId = useSelector(state => state.user.partnerId);
  const dispatch = useDispatch();
  const [extendDeadlineModalOpen, setExtendDeadlineModalOpen] = useState(false);
  const [selectedAttemptId, setSelectedAttemptId] = useState(null);
  const [newDeadline, setNewDeadline] = useState(null);
  const handleExtendDeadline = async (attemptId, newDeadline) => {
    const zone = "Asia/Ho_Chi_Minh";
    const newDeadlineDateTime = DateTime.fromISO(newDeadline, { zone });
    const response = await extendAttemptDeadlineAPI(attemptId, newDeadlineDateTime.toISO())
    if (response.code === 1000) {
      message.success('Update deadline successfully');
      fetchAttemptData();
    } else {
      message.error('Update deadline failed');
    }
    setExtendDeadlineModalOpen(false);
  };
  const handleOpenExtendDeadlineModal = (attemptId) => {
    setSelectedAttemptId(attemptId);
    setNewDeadline(null);
    setExtendDeadlineModalOpen(true);
  };
  const handleCloseExtendDeadlineModal = () => {
    setSelectedAttemptId(null);
    setNewDeadline(null);
    setExtendDeadlineModalOpen(false);
  };
  const handleViewDetails = (attemptId) => {
    navigate(`/score?attemptId=${attemptId}`);
  };
  const handleDeleteAttempt = async (attemptId) => {
    Modal.confirm({
      title: 'Delete Attempt',
      content: 'Are you sure you want to delete this attempt?',
      onOk: async () => {
        try {
          await deleteAttemptAPI(attemptId);
          message.success('Delete attempt successfully');
          fetchAttemptData();
        } catch (error) {
          message.error('Delete attempt failed');
        }
      },
    });
  }

  useEffect(() => {
    dispatch(resetMock());
  }, [dispatch]);

  const fetchAttemptData = useCallback(async () => {
    setLoading(true);
    try {
      if (roles.some(role => role.name === ROLE.TEACHER)) {
        const response = await fetchAllAttemptsAPI();
        const data = response.data.result;
        setAttemptList(data);
      } else if (roles.some(role => role.name === ROLE.SALE_PARTNER)) {
        const response = await fetchAttemptsByPartnerIdAPI(partnerId);
        const data = response.data.result;
        setAttemptList(data);
      }
    } catch (error) {
      console.error("Error fetching attempts:", error);
    } finally {
      setLoading(false);
    }
  }, [roles, partnerId]);

  useEffect(() => {
    fetchAttemptData();
  }, [fetchAttemptData]);

  const statusOrder = {
    NOT_START: 0,
    EXPIRED: 1,
    IN_PROCESS: 2,
    FINISH: 3,
  };

  const filteredAttempts = attemptList
    .filter(attempt =>
      attempt.username.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(attempt =>
      typeFilter === 'all' ? true : attempt.type === typeFilter
    )
    .sort((a, b) => {
      const statusDiff = statusOrder[a.status] - statusOrder[b.status];
      
      if (statusDiff === 0) {
        if (!a.createDate) return 1;  // a is null, so it should come after
        if (!b.createDate) return -1; // b is null, so it should come after
        
        return new Date(b.createDate) - new Date(a.createDate);
      }
      
      return statusDiff;
    });



  const columns = [
    {
      title: '#',
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'User',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Mock Name',
      dataIndex: 'mockName',
      key: 'mockName',
    },
    {
      title: 'Finish Time',
      dataIndex: 'finishTime',
      key: 'finishTime',
      render: (finishTime) => finishTime ? timeFormat(finishTime) : 'N/A',
    },
    {
      title: 'Deadline Time',
      dataIndex: 'deadlineTime',
      key: 'deadlineTime',
      render: (deadlineTime) => timeFormat(deadlineTime),
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={
          status === 'FINISH' ? 'status-finish' :
            status === 'IN_PROCESS' ? 'status-in-progress' :
              'status-not-started'
        }>
          {statusMap(status)}
        </span>
      ),
      sorter: (a, b) => statusOrder[b.status] - statusOrder[a.status],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => typeMap(type),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          {
            record.status === 'NOT_START' && (
              <button
                className="btn btn-danger btn-sm mx-1"
                onClick={() => handleDeleteAttempt(record.attemptId)}
              >
                Delete
              </button>
            )
          }
          {(record.status === 'FINISH' || record.status === 'IN_PROCESS') && (
            <button
              className="btn btn-dark btn-sm mx-1"
              onClick={() => handleViewDetails(record.attemptId)}
            >
              View Result
            </button>
          )}
          {record.status === 'EXPIRED' && (
            <button
              className="btn btn-dark btn-sm mx-1"
              onClick={() => handleOpenExtendDeadlineModal(record.attemptId)}
            >
              Extend Deadline
            </button>
          )}
        </>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container mt-5">
      <h2 className="fw-semibold text-center mb-4">All Attempts</h2>
      <div className="mb-3 d-flex gap-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by username..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Select
          style={{ width: 200 }}
          value={typeFilter}
          onChange={(value) => setTypeFilter(value)}
        >
          <Option value="all">All Types</Option>
          <Option value="PRACTICE_TEST">Practice Test</Option>
          <Option value="ENTRANCE_TEST">Entrance Test</Option>
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={filteredAttempts}
        rowKey="attemptId"
        loading={loading}
        defaultSortField="status"
        defaultSortOrder="ascend"
      />
      <Modal
        title="Extend Deadline"
        open={extendDeadlineModalOpen}
        onCancel={handleCloseExtendDeadlineModal}
        onOk={() => handleExtendDeadline(selectedAttemptId, newDeadline)}
      >
        <p>Select new deadline?</p>
        <input
          type="datetime-local"
          className="form-control"
          id="deadlineTime"
          min={new Date().toISOString().slice(0, 16)} // Giới hạn ngày giờ không được nhỏ hơn hiện tại
          value={newDeadline}
          onChange={(e) => setNewDeadline(e.target.value)}
          required
        />
      </Modal>
    </div>
  );
};

export default AllAttempt;
