import React from 'react'
import '../../pages/Mock/Mock.css';
import { useSelector } from 'react-redux';
import MathRender from '../Math/MathRender';
import './ChoiceReview.css';
export default function ChoiceReview(props) {
    const currentQuestion = useSelector((state) => state.mock.currentQuestion);
    const selectedChoice = useSelector(state => state.mock.choices[currentQuestion.id]);
    const answer = useSelector(state => state.mock.currentQuestion.answer);

    const handleRenderButtonClass = () => {
        if (!props.isShowAnswer) {
            return 'btn-outline-main-color';
        }
        if (selectedChoice === undefined) {
            if (answer === props.customKey) {
                return 'btn-choice-outline-main-color-correct';
            }
        }
        if (props.customKey === selectedChoice && props.customKey !== answer) {
            return 'btn-choice-outline-main-color-incorrect';
        }
        if (props.customKey === answer) {
            return 'btn-choice-outline-main-color-correct';
        }

        return 'btn-outline-main-color';
    }
    const handleRenderKeyClass = () => {
        if (!props.isShowAnswer) {
            return 'btn-key';
        }
        if (selectedChoice === undefined) {
            if (answer === props.customKey) {
                return 'btn-key-correct';
            }
        }
        if (props.customKey === selectedChoice && props.customKey !== answer) {
            return 'btn-key-incorrect';
        }
        if (props.customKey === answer) {
            return 'btn-key-correct';
        }



        return 'btn-key';
    }

    return (
        <div className="choice-container d-flex">
            <button
                value={props.customKey}
                className={`choice-btn text-start px-3 py-2 w-100 my-2 btn 
                     ${handleRenderButtonClass()}`}
            ><div className='d-flex align-item'>
                    <div className={`${handleRenderKeyClass()}`}>{props.customKey}</div>
                    <MathRender text={props.text} />
                </div>


            </button>
        </div>
    )
}
