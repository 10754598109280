import { DOMAIN, SAT_TABLE } from "./constants";

export const calculateTotalScore = (mockData, studentChoices) => {
    if (!mockData || !studentChoices) return 0;
    let score = 0;
    mockData.forEach((question) => {
        if (question.answer.split(';').includes(studentChoices[question.id])) {
            score++;
        }
    });
    return score;
};
export const calculateDomainScore = (mockData, studentChoices) => {
    const domainScore = {
        infomationAndIdeas: 0,
        craftAndStructure: 0,
        expressionOfIdeas: 0,
        standardEnglishConventions: 0,
        algebra: 0,
        advancedMath: 0,
        problemSolvingAndDataAnalysis: 0,
        geometryAndTriginometry: 0,
    }
    mockData.forEach((question) => {
        if (question.answer.split(';').includes(studentChoices[question.id])) {
            if (question.domain === DOMAIN.INFORMATION_AND_IDEAS) {
                domainScore.infomationAndIdeas++;
            } else if (question.domain === DOMAIN.CRAFT_AND_STRUCTURE) {
                domainScore.craftAndStructure++;
            } else if (question.domain === DOMAIN.EXPRESSION_OF_IDEAS) {
                domainScore.expressionOfIdeas++;
            } else if (question.domain === DOMAIN.STANDARD_ENGLISH_CONVENTIONS) {
                domainScore.standardEnglishConventions++;
            } else if (question.domain === DOMAIN.ALGEBRA) {
                domainScore.algebra++;
            } else if (question.domain === DOMAIN.ADVANCED_MATH) {
                domainScore.advancedMath++;
            } else if (question.domain === DOMAIN.PROBLEM_SOLVING) {
                domainScore.problemSolvingAndDataAnalysis++;
            } else if (question.domain === DOMAIN.GEOMETRY_AND_TRIGINOMETRY) {
                domainScore.geometryAndTriginometry++;
            }
        }
    });
    return domainScore;
}

export const calculateScoreSAT = (rw1, rw2, math1, math2) => {
    // Số câu đúng tối đa của từng phần
    const MAX_RW = 54; // Reading & Writing tối đa
    const MAX_MATH = 44; // Math tối đa

    // Bảng tra SAT
    const satTable = SAT_TABLE || [];

    // 1. Điều chỉnh điểm nếu Adaptive Mode (RW1 < 18 hoặc Math1 < 15)
    const adjustedRW2 = rw1 < 18 ? Math.round(0.8 * rw2) : rw2;
    const adjustedMath2 = math1 < 15 ? Math.round(0.8 * math2) : math2;

    // 2. Tính tổng số câu đúng
    const totalRW = rw1 + adjustedRW2;
    const totalMath = math1 + adjustedMath2;

    // 3. Quy đổi chỉ số sang bảng SAT
    const rwIndex = Math.min(Math.round((totalRW / MAX_RW) * 66), 66); // Giới hạn index tối đa là 66
    const mathIndex = Math.min(Math.round((totalMath / MAX_MATH) * 54), 54); // Giới hạn index tối đa là 54

    // 4. Lấy điểm từ bảng tra SAT
    const rwScore = satTable[rwIndex].readingAndWriting;
    const mathScore = satTable[mathIndex].math;

    // 5. Tính tổng điểm SAT
    const totalScoreLower = rwScore.lower + mathScore.lower;
    const totalScoreUpper = rwScore.upper + mathScore.upper;

    // 6. Trả về kết quả
    const averageScore = Math.round((totalScoreLower + totalScoreUpper) / 2 / 10) * 10;

    return {
        rwScoreLower: rwScore.lower,
        rwScoreUpper: rwScore.upper,
        mathScoreLower: mathScore.lower,
        mathScoreUpper: mathScore.upper,
        totalScoreLower,
        totalScoreUpper,
        averageScore
    };
};
