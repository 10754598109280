import { Select, Table, Modal, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import { fetchPartnersAPI, fetchUserByRoleAPI } from '../../apis';
import { ROLE } from '../../utils/constants';
import Search from 'antd/es/transfer/search';
import SaleForm from '../../components/SaleForm/SaleForm';
import PasswordForm from '../../components/PasswordForm/PasswordForm';

const PartnerSaleManagement = () => {
    const [dataSource, setDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSale, setSelectedSale] = useState(null);
    const [partners, setPartners] = useState([]);
    const [isAddMode, setIsAddMode] = useState(false);
     
    // Hàm xử lý khi click vào nút xem chi tiết
    const handleViewDetail = (sale) => {
        setSelectedSale(sale);
        setIsModalOpen(true);
    };

    const handleSearch = (value) => {
        console.log('Search value:', value);
    };

    const fetchSaleList = async () => {
        const response = await fetchUserByRoleAPI(ROLE.SALE_PARTNER);
        setDataSource(response.result);
        setColumns(tableColumns);
    }
    const fetchPartnerList = async () => {
        const response = await fetchPartnersAPI();
        setPartners(response.result);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedSale(null);
        setIsAddMode(false);
    }
    const handleAddSale = () => {
        setIsAddMode(true);
        setIsModalOpen(true);
    }

    // Gọi API khi component được tạo
    useEffect(() => {
        fetchSaleList();
        fetchPartnerList();
    }, []);

    // Định nghĩa cấu trúc các cột
    const tableColumns = [
        { title: 'Username', dataIndex: 'username', key: 'username' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Phone', dataIndex: 'phone', key: 'phone' },
        { title: 'Partner', dataIndex: 'partner', key: 'partner', render: (partner) => partner?.name || 'N/A' },
        {
            title: 'Action',
            key: 'action',
            render: (sale) => (
                <button
                    className='btn btn-dark btn-sm'
                    onClick={() => handleViewDetail(sale)}
                >
                    View Detail
                </button>
            )
        }
    ];

    return (
        <div className='container'>
            <h1>Sale Management</h1>
            <div className='row'>
                <div className='col-8'>
                    <Search
                        placeholder="Search by name"
                        onSearch={handleSearch}
                    />
                </div>
                <div className='col-2'>
                    <Select
                        placeholder="Select a partner"
                        showSearch
                        style={{ width: '100%' }}
                        options={partners.map(partner => ({
                            value: partner.id,
                            label: partner.name
                        }))}
                    />
                </div>
                <div className='col-2'>
                    <button
                        style={{ width: '100%' }}
                        type='primary'
                        className='btn btn-dark btn-sm'
                        onClick={handleAddSale}
                    >
                        Add A New Sale
                    </button>
                </div>
            </div>

            <Table
                dataSource={dataSource}
                columns={columns}
            />
            <Modal
                open={isModalOpen}
                width={900}
                destroyOnClose={true}
                onCancel={handleCancel}
                footer={null}
            >
                <Tabs>
                    <Tabs.TabPane tab="Sale Account" key="1">
                        <SaleForm
                            selectedSale={selectedSale}
                            isAddMode={isAddMode}
                            partnerList={partners}
                            onClose={handleCancel}
                            fetchSaleList={fetchSaleList}
                        />
                    </Tabs.TabPane>
                    {!isAddMode && <Tabs.TabPane tab="Password" key="2">
                        <PasswordForm
                            selectedUser={selectedSale}
                            onCancel={handleCancel}
                        />
                    </Tabs.TabPane>}
                </Tabs>
            </Modal>
        </div>
    );
};

export default PartnerSaleManagement;
