import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import "./MathRender.css"
const MathRender = (props) => {
    return (
        <Latex 
            className={props.className}
            delimiters={
                [
                    { left: '$$', right: '$$', display: true },
                    { left: '\\(', right: '\\)', display: false},
                   
                    { left: '\\[', right: '\\]', display: true},
                  ]
            }
        strict>{props.text}</Latex> // This is the text that will be rendered as math with dangerouseHTML
    );
}

export default MathRender;