import React from 'react'
import './Logo.css'
import logo from '../../Assets/images/logo.png'
import { Link } from 'react-router-dom'

 const Logo = () => {
  return (
    <div className='logo'>
        <div className='logo-icon'> 
            <Link to='/'>
            <img src={logo} alt='logo' />
            </Link>
        </div>
    
    </div>
  )
}

export default Logo;
