import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import { createSaleForPartnerAPI, updateUserAPI } from '../../apis';
import { ROLE } from '../../utils/constants';

const SaleForm = ({ selectedSale, isAddMode, partnerList, onClose, fetchSaleList }) => {
    const [form] = Form.useForm();
    const [selectedPartner, setSelectedPartner] = useState(null);
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...selectedSale,
            partnerId: selectedSale?.partner?.id
        });
        setSelectedPartner(selectedSale?.partner);
    }, [selectedSale]);

    const handleSubmit = async () => {
        let data = form.getFieldsValue();
        data.roles = [ROLE.SALE_PARTNER];
        data.partnerId = selectedPartner?.id;

        const handleAddSale = async () => {
            await createSaleForPartnerAPI(selectedPartner?.id, data);
            message.success('Create sale account successfully!');
            fetchSaleList();
        }
        const handleUpdateSale = async () => {
            await updateUserAPI(selectedSale.id, data);
            message.success('Update sale account successfully!');
            fetchSaleList();
        }
        try {
            if (isAddMode) {
                handleAddSale();
            } else {
                handleUpdateSale();
            }
        } catch (error) {
            message.error(error.message);
        }
        onClose();
        fetchSaleList();


    };

    return (
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input disabled={!isAddMode} />
            </Form.Item>
            {isAddMode && (
                <>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The passwords do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </>
            )}
            <Form.Item
                label="Partner" name="partnerId"
                rules={[{ required: true, message: 'Please select a partner!' }]}
            >
                <Select
                    onChange={(value) => {
                        setSelectedPartner(partnerList.find(partner => partner.id === value));
                    }}
                >
                    {partnerList?.map(partner => (
                        <Select.Option key={partner.id} value={partner.id}>
                            {partner.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Full Name"
                name="name"
                rules={[{ required: true, message: 'Please input your full name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Email" name="email">
                <Input />
            </Form.Item>
            <Form.Item label="Phone" name="phone">
                <Input />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType='submit' style={{ marginRight: '10px' }}>
                    {isAddMode ? "Add Sale" : "Update Sale"}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SaleForm;
