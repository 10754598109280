import { format } from "date-fns"
import { DOMAIN } from "./constants"

export const typeMap = (type) => {
    switch (type) {
        case 'PRACTICE_TEST':
            return 'Practice Test'
        case 'ENTRANCE_TEST':
            return 'Entrance Test'
        default:
            return 'Unknown'
    }
}

export const statusMap = (status) => {
    switch (status) {
        case 'FINISH':
            return 'Finished'
        case 'IN_PROCESS':
            return 'In Progress'
        case 'EXPIRED':
            return 'Expired'
        case 'NOT_STARTED':
            return 'Not Started'
        default:
            return 'Not Started'
    }
}

export const timeFormat = (time) => {
    if (!time) return 'N/A'
    return format(new Date(time), 'hh:mm a, dd-MM-yyyy')
}

export const isCorrectAnswer = (answer, studentChoice) => {
    return answer.split(';').includes(studentChoice)
}
export const countDomain = (mockData) => {
    const domainQuantity = {
        infomationAndIdeas: 0,
        craftAndStructure: 0,
        expressionOfIdeas: 0,
        standardEnglishConventions: 0,
        algebra: 0,
        advancedMath: 0,
        problemSolvingAndDataAnalysis: 0,
        geometryAndTriginometry: 0,
        total: 0,
    }
    mockData.forEach((question) => {
        if (question.domain === DOMAIN.INFORMATION_AND_IDEAS) {
            domainQuantity.infomationAndIdeas += 1
        }
        if (question.domain === DOMAIN.CRAFT_AND_STRUCTURE) {
            domainQuantity.craftAndStructure += 1
        }
        if (question.domain === DOMAIN.EXPRESSION_OF_IDEAS) {
            domainQuantity.expressionOfIdeas += 1
        }
        if (question.domain === DOMAIN.STANDARD_ENGLISH_CONVENTIONS) {
            domainQuantity.standardEnglishConventions += 1
        }
        if (question.domain === DOMAIN.ALGEBRA) {
            domainQuantity.algebra += 1
        }
        if (question.domain === DOMAIN.ADVANCED_MATH) {
            domainQuantity.advancedMath += 1
        }
        if (question.domain === DOMAIN.PROBLEM_SOLVING) {
            domainQuantity.problemSolvingAndDataAnalysis += 1
        }
        if (question.domain === DOMAIN.GEOMETRY_AND_TRIGINOMETRY) {
            domainQuantity.geometryAndTriginometry += 1
        }
    })
    domainQuantity.total = domainQuantity.infomationAndIdeas + domainQuantity.craftAndStructure + domainQuantity.expressionOfIdeas + domainQuantity.standardEnglishConventions + domainQuantity.algebra + domainQuantity.advancedMath + domainQuantity.problemSolvingAndDataAnalysis + domainQuantity.geometryAndTriginometry
    return domainQuantity
}

export const getPercentageAndQuestion = (domainName) => {
    let percentage = 0;
    let question = "";
    switch (domainName) {
        case "Information and Ideas":
            percentage = 26;
            question = "12-14";
            break;
        case DOMAIN.CRAFT_AND_STRUCTURE:
            percentage = 28;
            question = "13-15";
            break;
        case DOMAIN.EXPRESSION_OF_IDEAS:
            percentage = 20;
            question = "8-12";
            break;
        case DOMAIN.STANDARD_ENGLISH_CONVENTIONS:
            percentage = 26;
            question = "11-15";
            break;
        case DOMAIN.ALGEBRA:
            percentage = 35;
            question = "13-15";
            break;
        case DOMAIN.ADVANCED_MATH:
            percentage = 35;
            question = "13-15";
            break;
        case "Problem Solving & Data Analysis":
            percentage = 15;
            question = "5-7";
            break;
        case "Geometry & Triginometry":
            percentage = 15;
            question = "5-7";
            break;
        default:
            break;
    }
    return { percentage, question }
}