// src/components/Layout.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './UserLayout.css'
import { Button, Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Logo from '../Logo/Logo';
import MenuList from '../MenuList/MenuList';
import { Content, Header } from 'antd/es/layout/layout';
const UserLayout = ({ handleLogout, children }) => {
  const location = useLocation();
  const isHideNavbar = ['/mock'].some(route => location.pathname.startsWith(route));
  const [collapsed, setCollapsed] = useState(true);

  if (isHideNavbar) {
    return <div style={{ height: '100vh', width: '100vw' ,backgroundColor:'# '}}>{children}</div>;
  }
  

  return (
    <div>
      <Layout>
        <Sider className='sidebar' collapsed={collapsed}
        collapsible
       
        trigger={null}
        theme='light'
        >
          <Logo />
          <MenuList />
        </Sider>
        <Layout>
        <Header style={{ padding: 0, backgroundColor: 'white' }}>
          <Button
            type='text'
            className="toggle-sidebar"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} />
        </Header>
        <Content style={{ backgroundColor:'#fff' }}>
            {children}
          </Content>  
        </Layout>
      </Layout>


    </div>
  );
};

export default UserLayout;
