import { Select, Table, Modal, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import { fetchPartnersAPI, fetchStudentsByPartnerIdAPI, fetchUserByRoleAPI } from '../../apis';
import { ROLE } from '../../utils/constants';
import Search from 'antd/es/transfer/search';
import StudentForm from '../../components/StudentForm/StudentForm';
import PasswordForm from '../../components/PasswordForm/PasswordForm';
import { useSelector } from 'react-redux';
import { message } from 'antd';

const StudentManagement = () => {
    const [dataSource, setDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    const [partners, setPartners] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const roles = useSelector(state => state.user.roles);
    const partnerId = useSelector(state => state?.user?.partnerId);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isAddMode, setIsAddMode] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [searchText, setSearchText] = useState('');
    // Hàm xử lý khi click vào nút xem chi tiết
    const handleViewDetail = (student) => {
        setIsAddMode(false);
        setSelectedStudent(student);
        setIsModalOpen(true);
    };
    const handleAddStudent = () => {
        setIsAddMode(true);
        setSelectedStudent(null);
        setIsModalOpen(true);
    }

    const handleSearch = (value) => {
        setSearchText(value);
        const filteredData = originalData.filter(student => 
            student.username?.toLowerCase().includes(value.toLowerCase()) ||
            student.name?.toLowerCase().includes(value.toLowerCase())
        );
        setDataSource(filteredData);
    };

    const handleFilterPartner = () => {
        console.log('handleFilterPartner');
    };

    // Lấy dữ liệu sinh viên từ API
    const fetchStudentList = async () => {
        try {
            if (roles.some(role => role.name === ROLE.TEACHER)) {
                const response = await fetchUserByRoleAPI(ROLE.STUDENT);
                setOriginalData(response.result);
                setDataSource(response.result);
                setColumns(tableColumns);
            } else if (partnerId) {
                const response = await fetchStudentsByPartnerIdAPI(partnerId);
                setOriginalData(response.result);
                setDataSource(response.result);
                setColumns(tableColumns);
            }
        } catch (error) {
            message.error('Failed to fetch student list: ' + error.message);
        }
    }
    const fetchPartnerList = async () => {
        try {
            if (roles.some(role => role.name === ROLE.TEACHER)) {
                const response = await fetchPartnersAPI();
                setPartners(response.result);
            }
        } catch (error) {
            message.error(error.message);
        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    }

    // Gọi API khi component được tạo
    useEffect(() => {
        if (roles) {
            fetchStudentList();
            fetchPartnerList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles, partnerId]);

    // Định nghĩa cấu trúc các cột
    const tableColumns = [
        { title: 'Username', dataIndex: 'username', key: 'username' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Phone', dataIndex: 'phone', key: 'phone' },
        { title: 'City', dataIndex: 'city', key: 'city' },
        { title: 'Student From', dataIndex: 'partner', key: 'partner', render: (partner) => partner?.name || 'N/A' },
        {
            title: 'Action',
            key: 'action',
            render: (student) => (
                <button
                    className='btn btn-dark btn-sm'
                    onClick={() => handleViewDetail(student)}
                >
                    View Detail
                </button>
            )
        }
    ];

    return (
        <div className='container'>
            <h1>Student Management</h1>
            <div className='row'>
                <div className='col-6'>
                    <Search
                        placeholder="Search by username or name"
                        onSearch={handleSearch}
                        onChange={(e) => handleSearch(e.target.value)}
                        allowClear
                    />
                </div>
                <div className='col-4'>
                    {roles.some(role => role.name === ROLE.TEACHER) && <Select
                        placeholder="Select a partner"
                        showSearch
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={handleFilterPartner}
                        options={partners.map(partner => ({
                            value: partner.id,
                            label: partner.name
                        }))}
                    />}
                </div>
                <div className='col-2'>
                    <button style={{ width: '100%' }} type='primary' className='btn btn-dark btn-sm' onClick={handleAddStudent}>
                        Add A New Student
                    </button>
                </div>
            </div>

            <Table
                dataSource={dataSource}
                columns={columns}
            />
            <Modal
                open={isModalOpen}
                width={900}
                onCancel={handleCancel}
                destroyOnClose
                footer={null}
            >
                <Tabs defaultActiveKey={1}>
                    <Tabs.TabPane tab="Student Information" key="1">
                        <StudentForm
                            selectedStudent={selectedStudent}
                            onCancel={handleCancel}
                            isAddMode={isAddMode}
                            fetchStudentList={fetchStudentList}
                        />
                    </Tabs.TabPane>
                    {console.log(isAddMode)}
                    {!isAddMode && <Tabs.TabPane tab="Password" key="2">
                        <PasswordForm
                            selectedUser={selectedStudent}
                            onCancel={handleCancel}
                        />
                    </Tabs.TabPane>}
                </Tabs>
            </Modal>

        </div>
    );
};

export default StudentManagement;
