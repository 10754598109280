import React, { useState } from "react";
import axios from "axios";
import { API_ROOT } from "../../utils/constants";
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from "react-toastify";

const UploadMock = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [data, setData] = useState(null);

  const handleFileChange = (e) => {
    setData(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create formData to send multipart/form-data
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("data", data);

    try {
      // Send request to your API
      const response = await axios.post(`${API_ROOT}mocks`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Log response if successful
      console.log(response.data);
      toast.success("Mock uploaded successfully");
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="fw-semibold mb-4">Upload New Mock</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Name:</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Enter name"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Description:</label>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            placeholder="Enter description"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Upload JSON File:</label>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
            required
            accept=".json"
          />
        </div>
        <button type="submit" className="btn btn-dark">Upload Mock</button>
      </form>
    </div>
  );
};

export default UploadMock;
