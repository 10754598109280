import React, { useState, useEffect } from 'react';
import { fetchUsersAPI, fetchMocksAPI, createAttemptAPI, fetchStudentsByPartnerIdAPI, fetchMocksForPartnerAPI } from '../../apis';
import { toast } from 'react-toastify';
import { Select } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../components/Loading/Loading';
import { useSelector } from 'react-redux';
import { ROLE } from '../../utils/constants';

const { Option } = Select;

function AssignAttempt() {
  const [username, setUsername] = useState('');
  const [mockId, setMockId] = useState('');
  const [attemptType, setAttemptType] = useState('');
  const [deadlineTime, setDeadlineTime] = useState('');
  const [users, setUsers] = useState([]);
  const [mocks, setMocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const roles = useSelector(state => state.user.roles);
  const partnerId = useSelector(state => state.user.partnerId);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (roles.some(role => role.name === ROLE.TEACHER)) {
          const usersData = await fetchUsersAPI();
          const mocksData = await fetchMocksAPI();
          setMocks(mocksData.result.sort((a, b) => a.name.localeCompare(b.name)));
          setUsers(usersData.result);
        } else if (roles.some(role => role.name === ROLE.SALE_PARTNER)) {
          if (partnerId) {
            const usersData = await fetchStudentsByPartnerIdAPI(partnerId);
            setUsers(usersData.result);
            const mocksData = await fetchMocksForPartnerAPI();
            setMocks(mocksData.result.sort((a, b) => a.name.localeCompare(b.name)));
          }
        }

      } catch (error) {
        toast.error('Error fetching data from the server.');
      }
      setLoading(false);
    };
    fetchData();
  }, [roles, partnerId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedDeadlineTime = new Date(deadlineTime).toISOString();

    const attemptData = {
      username,
      mockId,
      type: attemptType,
      deadlineTime: formattedDeadlineTime,
    };
    try {
      await createAttemptAPI(attemptData);
      toast.success('Mock assigned successfully!');
      setUsername('');
      setMockId('');
      setDeadlineTime('');
      setAttemptType('');
    } catch (error) {
      toast.error('Error assigning mock.');
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h2 className="fw-semibold text-center mb-4">Assign Attempt to Student</h2>
              {loading ? (
                <Loading />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <label htmlFor="username" className="form-label">Username:</label>
                    <Select
                      showSearch
                      placeholder="Select a user"
                      value={username}
                      onChange={setUsername}
                      className="form-control p-0"
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      required
                      options={users?.map((user) => ({
                        value: user.username,
                        label: `username: ${user.username} | fullname: ${user.name}`
                      }))}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="attemptType" className="form-label">Attempt Type:</label>
                    <Select
                      placeholder="Select attempt type"
                      value={attemptType}
                      onChange={setAttemptType}
                      className="form-control p-0"
                      required
                    >
                      {roles.some(role => role.name === ROLE.TEACHER) && <Option key="PRACTICE_TEST" value="PRACTICE_TEST">Practice Test</Option>}
                      {<Option key="ENTRANCE_TEST" value="ENTRANCE_TEST">Entrance Test</Option>}
                    </Select>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="mockName" className="form-label">Mock Name:</label>
                    <Select
                      showSearch
                      placeholder="Select a mock"
                      value={mockId}
                      onChange={setMockId}
                      className="form-control p-0"
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      required
                      options={mocks.map((mock) => ({
                        value: mock.id,
                        label: `${mock.name}: ${mock.description}`
                      }))}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="deadlineTime" className="form-label">Deadline Time:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="deadlineTime"
                      min={new Date().toISOString().slice(0, 16)} // Giới hạn ngày giờ không được nhỏ hơn hiện tại
                      value={deadlineTime}
                      onChange={(e) => setDeadlineTime(e.target.value)}
                      required
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-dark w-100">Assign Attempt</button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignAttempt;