import './PercentBar.css'
const PercentBar = ({ trueQuantity, totalQuantity }) => {
    const calculatePercent = () => {
        const percentTrue = trueQuantity / totalQuantity
        const trueElement = Math.round(percentTrue * 7)
        const falseElement = 7 - trueElement
        return { trueElement, falseElement }
    }
    return (
        <div className='d-flex flex-row items-center justify-center gap-1'>
            {Array.from({ length: calculatePercent().trueElement }).map(() => (
                <div className='percent-bar-element-true'></div>
            ))}
            {Array.from({ length: calculatePercent().falseElement }).map(() => (
                <div className='percent-bar-element-false'></div>
            ))}
        </div>
    )
}

export default PercentBar