import React from 'react'


function Loading() {
    
    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border" role="status"></div>

            {/* <div>  Loading ....</div> */}
        </div>

    )
}

export default Loading