import '../../pages/Mock/Mock.css'
import './Review.css'
import '../../Assets/CSS/style.css'
import Desmos from 'desmos';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuestion } from '../../redux/slice/mockSlice';
import { useState, useEffect, useRef } from 'react';
import MathRenderer from '../../components/Math/MathRender';
import MathRule from '../../components/Math/MathRule';
import MathReview from '../../components/Math/MathReview';
import MathRender from '../../components/Math/MathRender';
import Preview from '../../components/PopupNextSection/Preview';
import ChoiceReview from '../ChoiceReview/ChoiceReview';
import { isCorrectAnswer } from '../../utils/helper';



function Review() {

    const dispatch = useDispatch();
    const [isPreview, setIsPreview] = useState(false);
    const currentQuestion = useSelector((state) => state?.mock?.currentQuestion);
    const choice = useSelector(state => state?.mock?.choices?.[currentQuestion.id]);
    const inputValue = useSelector(state => state?.mock?.choices[currentQuestion.id]);
    const [isShowAnswer, setIsShowAnswer] = useState(false);

    const handleBackButton = () => {
        if (currentQuestion.id === 1) {
            return;
        }
        setIsShowAnswer(false);
        dispatch(setCurrentQuestion(currentQuestion.id - 1));
    }
    const handleShowAnswer = () => {
        setIsShowAnswer(!isShowAnswer);
    }

    const handleNextButton = () => {
        if (currentQuestion.id === 98) {
            return;
        }
        setIsShowAnswer(false);
        dispatch(setCurrentQuestion(currentQuestion.id + 1));
    }

    const [isDesmosOpen, setIsDesmosOpen] = useState(false); // State to control Desmos popup
    const desmosRef = useRef(null); // Reference to hold Desmos container
    const calculatorRef = useRef(null); // Reference for Desmos instance

    const toggleDesmos = () => {
        setIsDesmosOpen(!isDesmosOpen);
    };
    useEffect(() => {
        if (isDesmosOpen && desmosRef.current) {
            calculatorRef.current = Desmos.GraphingCalculator(desmosRef.current);
        }

        // Cleanup function to destroy Desmos when closed
        return () => {
            if (calculatorRef.current) {
                calculatorRef.current.destroy();
                calculatorRef.current = null;
            }
        };
    }, [isDesmosOpen]);

    const renderSection = (questionId) => {
        switch (true) {
            case questionId >= 1 && questionId <= 27:
                return "Section 1, Module 1: \n Reading & Writing"
            case questionId >= 28 && questionId <= 54:
                return "Section 1, Module 2: \n Reading & Writing"
            case questionId >= 55 && questionId <= 76:
                return "Section 2, Module 1: \n Math"
            case questionId >= 77 && questionId <= 98:
                return "Section 2, Module 2: \n Math"
            default:
                return "1";
        }
    }
    const renderTitleContainer = (currentQuestion, choice) => {
        if (!isShowAnswer) {
            return <div className={`title-container-normal`}>
                <p className={`question-number-normal`}>{currentQuestion.id}</p>
                <button className='btn' onClick={handleShowAnswer}>
                    <span>
                        Show Answer <i className="bi bi-eye"></i>
                    </span>
                </button>
            </div>;
        }
        if (choice === undefined) {
            return (
                <div className={`title-container-skipped`}>
                    <p className={`question-number-skipped`}>{currentQuestion.id}</p>
                    <h5 className='m-0 ms-2 '>Skipped</h5>
                </div>
            );
        }
        let isCorrect = isCorrectAnswer(currentQuestion?.answer, choice) ? "correct" : "incorrect";
        return (
            <div className={`title-container-${isCorrect}`}>
                <p className={`question-number-${isCorrect}`}>{currentQuestion.id}</p>
                <h5 className='m-0 ms-2'>{isCorrect === "correct" ? "Correct" : "Incorrect"}</h5>
            </div>
        );
    }
    const handleRenderBodyMI = () => {
        return (
            <div>
                {isShowAnswer && <h5 className="text-start mt-2">Your Answer: &nbsp;<MathReview input={inputValue} /> </h5>}
                {isShowAnswer && <h6 className={`text-start mt-2 ${choice === undefined ? "math-skipped" : isCorrectAnswer(currentQuestion?.answer, choice) ? "math-correct" : "math-incorrect"}`}>
                    {
                        choice === undefined ? (
                            `The correct answer is: ${currentQuestion.answer} `
                        ) : (
                            (isCorrectAnswer(currentQuestion?.answer, choice) ? "Correct" : "Incorrect")
                        )
                    }
                </h6>}
                {choice !== undefined && !isCorrectAnswer(currentQuestion?.answer, choice) && isShowAnswer && <h6 className="text-start mt-2 math-correct">The correct answer is: <MathReview input={currentQuestion.answer} /> </h6>}
            </div>
        );
    }





    return (
        <div className="mock-page">

            <div className="text-center">
                <div className="mx-auto cold-md-6 py-2 main-card-test" style={{ position: 'relative' }}>
                    {/* Top Section */}
                    {!isPreview && <section className='mx- col-md-12  d-flex flex-row justify-content-between main-header-test'>
                        <div className="text-left d-flex flex-row align-items-center" style={{ width: '400px' }}>
                            <h3 className="m-0 medium font-20">{renderSection(currentQuestion.id)}</h3>
                            <div className="d-flex align-items-center">
                                <button className="btn  btn-dark btn-sm mx-2" onClick={handleBackButton}>
                                    <i className="bi bi-arrow-left"></i>
                                </button>
                                <button className="btn  btn-dark btn-sm" onClick={handleNextButton}>
                                    <i className="bi bi-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                        {/* Timer */}
                        <div className="align-items-center ">

                        </div>
                        <div className="d-flex view-more">
                            <button className='medium d-inline btn' onClick={handleShowAnswer}>
                                <i className="bi bi-eye"></i>
                                <br />
                                Show Answer
                            </button>
                            {currentQuestion.skill !== "RW" && <button className='medium d-inline btn' onClick={toggleDesmos}>
                                <i className="bi bi-calculator-fill"></i>
                                Calculator
                            </button>}
                            <button className='medium d-inline btn' >
                                <i className="bi bi-three-dots-vertical"></i>
                                <br />
                                More
                            </button>
                        </div>
                    </section>}
                    <hr style={{ border: '1px solid #000', margin: '0 0 15px 0' }} />
                    {/* Middle Section  */}
                    {isPreview ?
                        <Preview setIsPreview={setIsPreview} /> :
                        <section className="mid-section col-md-12 row text-center overflow-auto">

                            {/* Left Container */}

                            {currentQuestion.skill === 'MI' ?
                                <MathRule />
                                :
                                (currentQuestion.skill !== "MA" ?
                                    <div className={`
                                            overflow-auto 
                                            col-sm-12 
                                        
                                            ${currentQuestion.skill === "MA" ? "col-md-12" : "col-md-6 row-with-divider"} 
                                            p-4  
                                            text-start
                                            left-divider`
                                    }>
                                        <div>
                                            <MathRender text={currentQuestion.passage} />
                                        </div>


                                    </div> : null)}
                            {/* Right Container */}

                            <div className={`overflow-auto  col-sm-12  
       ${currentQuestion.skill === "MA" ? "col-md-7 m-auto" : "col-md-6 row-with-divider"} 
       row-with-divider p-4`}>
                                {renderTitleContainer(currentQuestion, choice)}

                                <p className="my-4 text-start question-body" id="question"><MathRenderer text={currentQuestion.question} /></p>
                                {/* Choices Container */}
                                <div className="choices-container">

                                    {currentQuestion.skill === "MI" ?
                                        handleRenderBodyMI()
                                        :
                                        currentQuestion.choices.map((choice, index) =>
                                            <ChoiceReview key={`${choice.key}-${index}`} customKey={choice.key} text={choice.text} isShowAnswer={isShowAnswer} />
                                        )}

                                </div>
                            </div>
                        </section>}

                </div>

            </div>

        </div>
    )
}

export default Review
