import { Form, Input, Button, message } from 'antd'
import React from 'react'
import { changePasswordAPI } from '../../apis';

const PasswordForm = ({ selectedUser, onCancel }) => {
    const [form] = Form.useForm();
    const handleSubmit = async () => {
        await changePasswordAPI(selectedUser.id, form.getFieldsValue())
            .then(() => {
                message.success('Password updated successfully');
                onCancel();
            })
            .catch((error) => {
                message.error(error.response.data.message);
            });
    }
    return (
        <div>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
                <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[
                        { required: true, message: 'Please input your password!' },
                        { min: 6, message: 'Password must be at least 6 characters long' },
                    ]}
                >
                    <Input.Password placeholder="Enter new password" />
                </Form.Item>
                <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    rules={[
                        { min: 6, message: 'Password must be at least 6 characters long' },
                        { required: true, message: 'Please confirm your password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) return Promise.resolve();
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Enter confirm password" />
                </Form.Item>
                <div className="d-flex justify-content-end gap-2">
                    <Button type="primary" htmlType="submit">Save</Button>
                </div>
            </Form>

        </div>
    )
}

export default PasswordForm