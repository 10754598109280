import React, { useEffect, useState } from 'react';
import { fetchAtteptByUserIdAPI } from '../../apis';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetMock } from '../../redux/slice/mockSlice';
import './ActivitiesPage.css'; // Import your CSS file
import { statusMap, timeFormat } from '../../utils/helper';
import { Table } from 'antd';

const ActivitiesPage = () => {
  const [attemptList, setAttemptList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.id);
  const handleStartMock = (attemptId) => {
    navigate(`/mock/${attemptId}`);
    dispatch(resetMock());
  };


  const handleScoreAttempt = (attemptId) => {
    dispatch(resetMock());
    navigate(`/score?attemptId=${attemptId}`);
  };

  useEffect(() => {
    setLoading(true);
    const fetchMockData = async () => {
      try {
        const response = await fetchAtteptByUserIdAPI(userId);
        const data = response.data.result;
        setAttemptList(data);
      } catch (error) {
        console.error("Error fetching attempts:", error);
      } finally {
        setLoading(false);
      }
    };
    dispatch(resetMock());
    fetchMockData();
    // eslint-disable-next-line 
  }, [userId]);

  if (loading) {
    return <Loading />;
  }

  const columns = [
    {
      title: '#',
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Mock Name',
      dataIndex: 'mockName',
      key: 'mockName',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => type === 'PRACTICE_TEST' ? 'Practice Test' : 'Entrance Test',
    },
    {
      title: 'Finish Time',
      dataIndex: 'finishTime',
      key: 'finishTime',
      render: (finishTime) => finishTime ? timeFormat(finishTime) : 'N/A',
    },
    {
      title: 'Deadline',
      dataIndex: 'deadlineTime',
      key: 'deadlineTime',
      render: (deadlineTime) => timeFormat(deadlineTime),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={
          status === 'FINISH' ? 'status-finish' :
          status === 'IN_PROCESS' ? 'status-in-progress' :
          'status-not-started'
        }>
          {statusMap(status)}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          {record.status === 'FINISH' && record.type === "PRACTICE_TEST" && (
            <button
              className="btn btn-secondary mx-1"
              onClick={() => handleScoreAttempt(record.attemptId)}
            >
              Result
            </button>
          )}
          {(record.status === 'NOT_START' || record.status === 'IN_PROCESS') && (
            <button
              className="btn btn-dark mx-1"
              onClick={() => handleStartMock(record.attemptId)}
            >
              {record.status === 'NOT_START' ? 'Start' : 'Continue'}
            </button>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="container mt-5">
      <h2 className="fw-bold text-start mb-4">Your Activities</h2>
      <Table 
        columns={columns}
        dataSource={attemptList}
        rowKey="attemptId"
        loading={loading}
        locale={{ emptyText: 'No attempts found' }}
      />
    </div>
  );
};

export default ActivitiesPage;
