import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchMock, setCurrentQuestion } from '../../redux/slice/mockSlice';
import { calculateDomainScore, calculateScoreSAT, calculateTotalScore } from '../../utils/calculateTotalScore'; // Import the score calculation function
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure you've imported Bootstrap CSS
import './ScorePage.css';
import sat_logo from '../../Assets/images/logo.png';
import Loading from '../../components/Loading/Loading';
import { countDomain, getPercentageAndQuestion, isCorrectAnswer, timeFormat } from '../../utils/helper';
import { Modal, Table } from 'antd';
import Review from '../../components/Review/Review';
import { updateAttemptAPI } from '../../apis';
import PercentBar from '../../components/PercentBar/PercentBar';
const ScorePage = () => {
    const mockData = useSelector((state) => state.mock.data);
    const studentChoices = useSelector((state) => state.mock.choices);
    const dispatch = useDispatch();
    const [scoreSections, setScoreSections] = useState({ section1: null, section2: null, section3: null, section4: null });
    const [detailedScores, setDetailedScores] = useState([]); // New state to hold detailed score information
    const [rwScoreLower, setRwScoreLower] = useState(0);
    const user = useSelector((state) => state.mock.user);
    const mockName = useSelector((state) => state.mock.mockName);
    const finishTime = useSelector((state) => state.mock.finishTime);
    const markedQuestions = useSelector((state) => state.mock.markedQuestions);
    const [rwScoreUpper, setRwScoreUpper] = useState(0);
    const [mathScoreLower, setMathScoreLower] = useState(0);
    const [mathScoreUpper, setMathScoreUpper] = useState(0);
    const [totalScoreLower, setTotalScoreLower] = useState(0);
    const [totalScoreUpper, setTotalScoreUpper] = useState(0);
    const [domainQuantity, setDomainQuantity] = useState({
        infomationAndIdeas: 0,
        craftAndStructure: 0,
        expressionOfIdeas: 0,
        standardEnglishConventions: 0,
        algebra: 0,
        advancedMath: 0,
        problemSolvingAndDataAnalysis: 0,
        geometryAndTriginometry: 0,
    });
    const [domainScore, setDomainScore] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const attemptId = searchParams.get('attemptId');
    const handleViewReview = (questionId) => {
        console.log(questionId);
        dispatch(setCurrentQuestion(questionId));
        setIsModalOpen(true);
    }
    const renderDomainScore = (
        domainName,
        trueQuantity,
        totalQuantity
    ) => {
        const { percentage, question } = getPercentageAndQuestion(domainName);
        return (
            <div className='col-md-12'>
                <h4 className='fw-semibold mb-0 mt-2 fs-6'>{domainName}</h4>
                <h6 style={{ fontSize: "15px" }} className='fw-medium mb-1'>{`(${percentage}% of test sections, ${question} questions)`}</h6>
                {/* <h6 style={{ fontSize: "15px" }} className='fw-medium mb-1'>{`(${Math.floor(totalQuantity / 98 * 100)}% of test sections, ${totalQuantity} questions)`}</h6> */}
                <PercentBar trueQuantity={trueQuantity} totalQuantity={totalQuantity} />
            </div>
        );
    }
    useEffect(() => {
        if (attemptId) {
            dispatch(fetchMock(attemptId));
        }
    }, [dispatch, attemptId]);


    useEffect(() => {
        const calculateScore = async () => {
            if (mockData && scoreSections.section1 !== null && scoreSections.section2 !== null && scoreSections.section3 !== null && scoreSections.section4 !== null) {
                const { rwScoreLower, rwScoreUpper, mathScoreLower, mathScoreUpper, totalScoreLower, totalScoreUpper } = await calculateScoreSAT(scoreSections.section1, scoreSections.section2, scoreSections.section3, scoreSections.section4);
                setRwScoreLower(rwScoreLower);
                setRwScoreUpper(rwScoreUpper);
                setMathScoreLower(mathScoreLower);
                setMathScoreUpper(mathScoreUpper);
                setTotalScoreLower(totalScoreLower);
                setTotalScoreUpper(totalScoreUpper);
            }
        }
        calculateScore();
    }, [mockData, scoreSections, attemptId]);
    useEffect(() => {
        if (totalScoreLower !== 0 && totalScoreUpper !== 0 && attemptId) {
            const updateAverageScore = async () => {
                const averageScore = Math.round((totalScoreLower + totalScoreUpper) / 2 / 10) * 10;
                await updateAttemptAPI(attemptId, null, null, null, averageScore, null);
            }
            updateAverageScore();
        }
    }, [totalScoreLower, totalScoreUpper, attemptId]);
    useEffect(() => {
        if (mockData && studentChoices) {
            //Calculate domain score
            setDomainScore(calculateDomainScore(mockData, studentChoices));
            //Count domain quantity
            setDomainQuantity(countDomain(mockData));
            // Calculate scores for each section
            const section1 = mockData.slice(0, 27);
            const section2 = mockData.slice(27, 54);
            const section3 = mockData.slice(54, 76);
            const section4 = mockData.slice(76, 98);

            const section1Score = calculateTotalScore(section1, studentChoices);
            const section2Score = calculateTotalScore(section2, studentChoices);
            const section3Score = calculateTotalScore(section3, studentChoices);
            const section4Score = calculateTotalScore(section4, studentChoices);

            setScoreSections({
                section1: section1Score,
                section2: section2Score,
                section3: section3Score,
                section4: section4Score,
                total: section1Score + section2Score + section3Score + section4Score,
            });

            // Populate the detailedScores state with question-level data
            const details = [
                ...section1.map((question, index) => ({
                    questionNumber: index + 1,
                    id: question.id,
                    section: "Module 1 R & W",
                    correctAnswer: question.answer,
                    studentAnswer: studentChoices[question.id] || "Skip",
                    isCorrect: isCorrectAnswer(question.answer, studentChoices[question.id]),
                })),
                ...section2.map((question, index) => ({
                    questionNumber: index + 1,
                    id: question.id,
                    section: "Module 2 R & W",
                    correctAnswer: question.answer,
                    studentAnswer: studentChoices[question.id] || "Skip",
                    isCorrect: isCorrectAnswer(question.answer, studentChoices[question.id]),
                })),
                ...section3.map((question, index) => ({
                    questionNumber: index + 1,
                    id: question.id,
                    section: "Module 1 Math",
                    correctAnswer: question.answer,
                    studentAnswer: studentChoices[question.id] || "Skip",
                    isCorrect: isCorrectAnswer(question.answer, studentChoices[question.id]),
                })),
                ...section4.map((question, index) => ({
                    questionNumber: index + 1,
                    id: question.id,
                    section: "Module 2 Math",
                    correctAnswer: question.answer,
                    studentAnswer: studentChoices[question.id] || "Skip",
                    isCorrect: isCorrectAnswer(question.answer, studentChoices[question.id]),
                })),
            ];

            setDetailedScores(details);
        }
    }, [mockData, studentChoices]);

    if (!mockData) return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Loading />
        </div>
    );

    // Thêm hàm này ở đầu component hoặc tạo file utils riêng
    const formatScore = (score) => {
        return score < 10 ? `0${score}` : score;
    };

    const columns = [
        {
            title: 'Question',
            dataIndex: 'questionNumber',
            key: 'questionNumber',
            width: '12%',
            render: (text, record) =>
                <>
                    <span className='fw-semibold'>Question {text}</span>
                    <span> {markedQuestions[record.id] ? <i className="mark-icon bi bi-bookmark-fill text-danger"></i> : null}</span>
                </>,
        },
        {
            title: 'Section',
            dataIndex: 'section',
            key: 'section',
            width: '19%',
        },
        {
            title: 'Correct Answer',
            dataIndex: 'correctAnswer',
            key: 'correctAnswer',
            render: (text) => <span className="fw-bold">{text.replace(";", " OR ")}</span>,
        },
        {
            title: 'Your Answer',
            dataIndex: 'studentAnswer',
            key: 'studentAnswer',
            render: (text, record) => (
                <span className={record.isCorrect ? "correct-answer" : (text === "Skip" ? "skip-answer" : "wrong-answer")}>
                    {text !== "Skip" && text}&nbsp;
                    {text === "Skip" ? "-- Skipped" : (record.isCorrect ? "Correct" : "Incorrect")}
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <button className='btn btn-sm btn-dark' onClick={() => handleViewReview(record.id)}>
                    Review
                </button>
            ),
        },
    ];

    return (
        <div className="container mt-5 ">
            <div className="row justify-content-center">
                <section className="col-md-12 justify-content-center row score-page-header p-0 m-0">
                    <div className="col-md-5 p-0">
                        <h1 className="fw-bold ">Your Score <br />Report</h1>
                    </div>
                    <div className="col-md-6 mt-1">
                        <h4>Name: <strong>{user?.name}</strong></h4>
                        <h4>Finish Time: <strong>{timeFormat(finishTime)}</strong></h4>
                    </div>
                    <div className="col-md-1 col-sm-2 col-3">
                        <div className='score-page-logo-container d-flex align-items-center justify-content-center'>
                            <img src={sat_logo} alt="avatar" className='score-page-logo img-fluid' />
                        </div>
                    </div>
                </section>
                <section className='score-body shadow-sm col-md-12 row border p-0 m-0 rounded-4'>
                    <div className='col-md-12 row border-bottom pt-2 pb-2 m-0'>
                        <div className='col-md-5 p-0'>
                            <h1 className='fw-bold mb-0'>{mockName}</h1>
                        </div>
                        <div className='col-md-6'>
                            <h2 className='fw-semibold mb-0'>Total Score &nbsp;  |<span className='fs-3'>  &nbsp; {totalScoreLower}-{totalScoreUpper}</span></h2>
                        </div>
                        <div className='col-md-1'></div>
                    </div>
                    <div className='col-md-12 row border-bottom'>
                        <div className='col-md-4 p-0'>
                            <div className='col-md-12 row p-3 pt-3'>
                                <h3 className='fw-semibold mb-0 fs-3'>Section Scores</h3>
                                <div className='col-md-8 border-end'>
                                    <h4 className='fw-medium mb-0 mt-2 fs-5'>Reading & Writing </h4>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <h4 className='fw-bold mb-0 fs-5'>{rwScoreLower}-{rwScoreUpper}</h4>
                                </div>
                                <div className='col-md-8 mt-2  border-end'>
                                    <h4 className='fw-medium mb-0 fs-5'>Math</h4>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <h4 className='fw-bold mb-0 fs-5'>{mathScoreLower}-{mathScoreUpper}</h4>
                                </div>
                            </div>
                            <div className='col-md-12 row p-1 border-top pb-4 m-0'>
                                {/* <h3 className='fw-semibold mb-0  fs-3'>Number of right answers</h3> */}
                                <div className='col-md-8 border-end mt-2'>
                                    <h4 className='fw-medium mb-0 mt-2 fs-6'>Module 1 Reading & Writing:</h4>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <h4 className='fw-bold mb-0 fs-5'>{formatScore(scoreSections.section1)} / 27</h4>
                                </div>
                                <div className='col-md-8 border-end mt-2'>
                                    <h4 className='fw-medium mb-0 mt-2 fs-6'>Module 2 Reading & Writing:</h4>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <h4 className='fw-bold mb-0 fs-5'>{formatScore(scoreSections.section2)} / 27</h4>
                                </div>
                                <div className='col-md-8 border-end mt-2'>
                                    <h4 className='fw-medium mb-0 mt-2 fs-6'>Module 1 Math:</h4>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <h4 className='fw-bold mb-0 fs-5'>{formatScore(scoreSections.section3)} / 22</h4>
                                </div>
                                <div className='col-md-8 border-end mt-2'>
                                    <h4 className='fw-medium mb-0 mt-2 fs-6'>Module 2 Math:</h4>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <h4 className='fw-bold mb-0 fs-5'>{formatScore(scoreSections.section4)} / 22</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 border-start pb-4'>
                            <h3 className='fw-semibold mb-0  pt-3 fs-3'>Knowledge and Skills</h3>
                            <h6 className='fw-regular mb-0 mt-2 fs-6'>View your performance across areas meansured on the SAT</h6>
                            {mockData && mockData[0].domain && <div className='col-md-12 row'>
                                <div className='col-md-6 pr-0'>
                                    <h3 className='fw-semibold mb-0 mt-2 fs-4'>Reading and Writing</h3>
                                    <div className='col-md-12 row'>
                                        {renderDomainScore("Information and Ideas", domainScore.infomationAndIdeas, domainQuantity.infomationAndIdeas)}
                                        {renderDomainScore("Craft and Structure", domainScore.craftAndStructure, domainQuantity.craftAndStructure)}
                                        {renderDomainScore("Expression of Ideas", domainScore.expressionOfIdeas, domainQuantity.expressionOfIdeas)}
                                        {renderDomainScore("Standard English Conventions", domainScore.standardEnglishConventions, domainQuantity.standardEnglishConventions)}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <h3 className='fw-semibold mb-0 mt-2 fs-4'>Math</h3>
                                    <div className='col-md-12 row'>
                                        {renderDomainScore("Algebra", domainScore.algebra, domainQuantity.algebra)}
                                        {renderDomainScore("Advanced Math", domainScore.advancedMath, domainQuantity.advancedMath)}
                                        {renderDomainScore("Problem Solving & Data Analysis", domainScore.problemSolvingAndDataAnalysis, domainQuantity.problemSolvingAndDataAnalysis)}
                                        {renderDomainScore("Geometry & Triginometry", domainScore.geometryAndTriginometry, domainQuantity.geometryAndTriginometry)}
                                    </div>
                                </div>
                            </div>}
                        </div>

                        <div className='col-md-8'>

                        </div>
                    </div>
                </section>


                {/* Detailed Score Table */}
                <div className="">
                    <div className="">
                        <h2 className="text-center fw-bold mb-4 mt-3">Detail Score </h2>
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={detailedScores}
                            rowKey="id"
                            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                        />
                    </div>
                </div>

            </div>
            <Modal
                open={isModalOpen}
                onCancel={() => { setIsModalOpen(false) }}
                footer={null}
                width={1200}

                centered
            >
                <Review />
            </Modal>
        </div>
    );
};

export default ScorePage;
