import { API_ROOT} from '../utils/constants';
import api from '../utils/authorizedAxious';
import axios from 'axios';
import { DateTime } from 'luxon';
import { message } from 'antd';

//* -Authentication- *//

export const fetchTokenAPI = async (username, password) => {
    try{
    const response = await api.post(`${API_ROOT}auth/login`, { username, password });
    return response;
    }catch(error){
        message.error("Username or password is incorrect")
    }
}
export const fetchLogoutAPi = async (token) => {
    const response = await axios.post(`${API_ROOT}auth/logout`, { token })
    return response;
}
//* -Role *//
export const fetchRolesAPI = async () => {
    const response = await api.get('/roles');
    return response.data;
}
export const updateRoleAPI = async (roleId, data) => {
    const response = await api.put(`/roles/${roleId}`, data);
    return response.data;
}

//* -User- *//


export const fetchUsersAPI = async () => {
    const response = await api.get('/users');
    return response.data;
};
export const fetchMyProfileAPI = async () => {
    const response = await api.get('/users/my-info');
    return response.data.result;
}
export const createStudentAPI = async (data) => {
    const response = await api.post('/users', data);
    return response.data;
}
export const deleteUserAPI = async (userId) => {
    const response = await api.delete(`/users/${userId}`);
    return response.data;
}
export const updateUserAPI = async (userId, data) => {
    const response = await api.put(`/users/${userId}`, data);
    return response.data;
}
export const changePasswordAPI = async (userId, data) => {
    const response = await api.patch(`/users/${userId}/password`, data);
    return response.data;
}
export const fetchUserByIdAPI = async (userId) => {
    const response = await api.get(`/users/${userId}`);
    return response.data;
}
export const fetchUserByRoleAPI = async (role) => {
    const response = await api.get(`/users?role=${role}`);
    return response.data;
}
export const fetchStudentsByPartnerIdAPI = async (partnerId) => {
    const response = await api.get(`/partners/${partnerId}/students`);
    return response.data;
}
export const fetchSalesByPartnerIdAPI = async (partnerId) => {
    const response = await api.get(`/partners/${partnerId}/sales`);
    return response.data;
}

// * -Partner- * //
export const fetchPartnersAPI = async () => {
    const response = await api.get('/partners');
    return response.data;
}
export const fetchPartnerByIdAPI = async (partnerId) => {
    const response = await api.get(`/partners/${partnerId}`);
    return response.data;
}
export const createPartnerAPI = async (data) => {
    const response = await api.post('/partners', data);
    return response.data;
}
export const updatePartnerAPI = async (partnerId, data) => {
    const response = await api.put(`/partners/${partnerId}`, data);
    return response.data;
}
export const createStudentForPartnerAPI = async (partnerId, data) => {
    const response = await api.post(`/partners/${partnerId}/students`, data);
    return response.data;
}
export const createSaleForPartnerAPI = async (partnerId, data) => {
    const response = await api.post(`/partners/${partnerId}/sales`, data);
    return response.data;
}



// * -Attempt- * //
export const createAttemptAPI = async (attemptData) => {
    const response = await api.post('/attempts', attemptData);
    return response.data;
}
export const fetchAtteptByUserIdAPI = async (userId) => {
    const response = await api.get(`/users/${userId}/attempts`);
    return response;
}
export const fetchAtteptByIdAPI = async (attemptId) => {
    try{
    const response = await api.get(`/attempts/${attemptId}`);
    return response.data;
    }catch(error){
        message.error(error.response?.data.message)
    }
}
export const fetchAllAttemptsAPI = async () => {
    const response = await api.get('/attempts');
    return response;
}
export const fetchAttemptsByPartnerIdAPI = async (partnerId) => {
    const response = await api.get(`/partners/${partnerId}/attempts`);
    return response;
}
export const deleteAttemptAPI = async (attemptId) => {
    try{
    const response = await api.delete(`/attempts/${attemptId}`);
    return response.data;
    }catch(error){
        message.error(error.response?.data.message)
    }
}
export const extendAttemptDeadlineAPI = async (attemptId, newDeadline) => {
    const response = await api.patch(`/attempts/${attemptId}/deadline`, { deadlineTime: newDeadline });
    return response.data;
}


//Cập nhật dữ liệu Attempt
export const updateAttemptAPI = async (attemptId, mock, time, status, score = 0.0, finishTime = null) => {
    let attemptData = {};
    if(mock){
        attemptData = {
            choices: mock.choices,
        markedQuestions: mock.markedQuestions,
        module: mock.module,
        excludedChoices: mock.excludedChoices,
        finishTime: finishTime,
        menu: mock.menu,
        currentQuestion: mock.currentQuestion,
        startAtQuestions: mock.startAtQuestions,
            endAtQuestions: mock.endAtQuestions,
        };
    }
    const jsonString = JSON.stringify(attemptData);
    const jsonFile = new Blob([jsonString], { type: 'application/json' });
    const formData = new FormData();
    if(mock){
        formData.append('file', jsonFile, "file.json");
        formData.append('module', mock.module);
    }
    if (status) {
        formData.append('status', status);
    }
    if (score) {
        formData.append('score', score);
    }
    if(time){
        formData.append('timer', time);
    }
    if (finishTime) {
        
        formData.append('finishTime', DateTime.now().setZone('Asia/Ho_Chi_Minh').toISO());
    }
    const response = await api.put(`/attempts/${attemptId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },

    });
    return response.data;
}

// * -Mock- * //
export const fetchMocksAPI = async () => {
    const response = await api.get('/mocks');
    return response.data;
}
export const fetchMocksForPartnerAPI = async () => {
    const response = await api.get(`/mocks/partners`);
    return response.data;
}
