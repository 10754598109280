import { Menu } from 'antd';
import './MenuList.css'
import {
    HomeOutlined, FileOutlined, AppstoreOutlined, UserOutlined, BookOutlined, LoginOutlined,TeamOutlined, LogoutOutlined, CheckCircleOutlined, HistoryOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setIsAuthorized, resetUser } from '../../redux/slice/userSlice';
import { ROLE } from '../../utils/constants';
const MenuList = () => {
    const isAuthorized = useSelector(state => state.user.isAuthorized);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = (key) => {
        navigate(key);
    }
    const roles = useSelector(state => state.user.roles);
    const isTeacher = roles.some(role => role.name === ROLE.TEACHER);
    const isPartnerSale = roles.some(role => role.name === ROLE.SALE_PARTNER);
    const handleLogout = () => {
        localStorage.clear();
        dispatch(setIsAuthorized(false));
        dispatch(resetUser());
        navigate('/login');
    }
    const location = window.location.pathname;
    const currentPath = location === '/' ? 'home' : location.substring(1);
    return (
        <Menu theme='light' mode='inline' className='menu-bar' defaultOpenKeys={['home']} selectedKeys={[currentPath]}>
            <Menu.Item icon={<HomeOutlined />} key='home' onClick={() => handleClick('/')}>
                Home
            </Menu.Item>
            {isAuthorized && <Menu.Item icon={<AppstoreOutlined />} key='activities' onClick={() => handleClick('/activities')}>
                Activities
            </Menu.Item>}

            {(isTeacher || isPartnerSale) &&
                <>

                    <Menu.SubMenu key='attempt' title="Attempt" icon={<BookOutlined />}>
                        <Menu.Item icon={<CheckCircleOutlined />} key='assign' onClick={() => handleClick('/assign')}>
                            Assign
                        </Menu.Item>
                        {/* <Menu.Item icon={<TrophyOutlined />} key='score'>
                            Score
                        </Menu.Item> */}
                        <Menu.Item icon={<HistoryOutlined />} key='all-attempt' onClick={() => handleClick('/all-attempt')}>
                            All attempt
                        </Menu.Item>
                    </Menu.SubMenu>

                    {isTeacher &&
                        <>
                            <Menu.Item icon={<FileOutlined />} key='upload' onClick={() => handleClick('/upload')}>
                                Upload Mock
                            </Menu.Item>
                            <Menu.Item icon={<TeamOutlined />} key='partner' onClick={() => handleClick('/partner-management')}>
                                Partner
                            </Menu.Item>
                        </>
                    }

                    <Menu.SubMenu key='user' title="User" icon={<UserOutlined />}>
                        <Menu.Item icon={<UserOutlined />} key='student' onClick={() => handleClick('/student-management')}>
                            Student
                        </Menu.Item>
                        {isTeacher && <Menu.Item icon={<UserOutlined />} key='sale' onClick={() => handleClick('/sale-management')}>
                            Sale
                        </Menu.Item>}
                        {/* <Menu.Item icon={<UserOutlined />} key='teacher' onClick={() => handleClick('/teacher-management')}>
                            Teacher
                        </Menu.Item> */}
                    </Menu.SubMenu>

                    {/* <Menu.Item icon={<SettingOutlined />} key='settings' onClick={() => handleClick('/settings')}>
                        Settings
                    </Menu.Item> */}
                </>
            }
            {isAuthorized ? <Menu.Item icon={<LogoutOutlined />} key='logout' onClick={() => handleLogout()}>
                Logout
            </Menu.Item> : <Menu.Item icon={<LoginOutlined />} key='login' onClick={() => handleClick('/login')}>
                Login
            </Menu.Item>}

        </Menu>

    )
}

export default MenuList;