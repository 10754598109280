import React, { useState, useRef, useEffect, useCallback } from "react";
import "./Highlight.css";
import { useDispatch } from "react-redux";
import { setHighlights } from "../../redux/slice/mockSlice";

const Highlight = ({ questionId, text, isLeft = true, isHighlightEnable = false }) => {
  const [id, setId] = useState(0);
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedHighlight, setSelectedHighlight] = useState(null);
  const dispatch = useDispatch();



  const containerRef = useRef();
  useEffect(() => {
    console.log(text);
  }, [text]);

  const updatePassage = (text, questionId) => {
    if (isLeft) {
      dispatch(setHighlights({ id: questionId - 1, passage: text }));
    } else {
      dispatch(setHighlights({ id: questionId - 1, question: text }));
    }
  }

  // Hàm xử lý khi chọn highlight
  const handleHighlight = (color) => {
    const selection = window.getSelection();
    if (!selection.toString()) return;

    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      // Lấy tất cả các node nằm trong vùng chọn
      const nodesInRange = [];
      const walkNodes = (node) => {
        if (range.intersectsNode(node)) {
          if (node.nodeType === Node.TEXT_NODE && node.textContent.trim()) {
            nodesInRange.push(node);
          } else {
            for (let child of node.childNodes) {
              walkNodes(child);
            }
          }
        }
      };

      walkNodes(range.commonAncestorContainer);

      // Highlight từng node trong vùng chọn
      nodesInRange.forEach((node) => {
        const nodeRange = document.createRange();
        nodeRange.selectNodeContents(node);

        // Nếu node là phần đầu hoặc cuối của vùng chọn
        if (node === range.startContainer) {
          nodeRange.setStart(node, range.startOffset);
        }
        if (node === range.endContainer) {
          nodeRange.setEnd(node, range.endOffset);
        }

        const span = document.createElement("span");
        span.style.backgroundColor = color;
        span.className = "highlighted";
        span.id = id;
        span.addEventListener("click", handleClickHighlighted);
        setId(id + 1);
        try {
          nodeRange.surroundContents(span); // Chèn <span> vào vùng chọn
        } catch (e) {
          console.error("Cannot surround node:", e);
        }
      });

      // Reset vùng chọn sau khi highlight
      selection.removeAllRanges();
      setMenuPosition(null); // Ẩn menu

      // Cập nhật lại nội dung bên trong <div id="count-scope">
      const scopeElement = document.getElementById(`count-scope${isLeft ? "-left" : "-right"}`);
      if (scopeElement) {
        updatePassage(scopeElement.innerHTML, questionId);
      }
    }
  };

  // Hàm xử lý khi nhấn chuột
  const handleMouseUp = (e) => {
    const selection = window.getSelection();
    if (selection.toString() && isHighlightEnable) {
      // Lấy vị trí của container
      const containerRect = containerRef.current.getBoundingClientRect();
      
      // Tính toán vị trí tương đối so với container
      const menuPosition = {
        top: (e.clientY - containerRect.top + 2) + 'px',
        left: (e.clientX - containerRect.left + 5) + 'px'
      };

      setMenuPosition(menuPosition);
      setSelectedHighlight(null);
    } else {
      setMenuPosition(null);
    }
  };

  const handleClickHighlighted = useCallback((e) => {
    const span = e.target;
    const range = document.createRange();
    range.selectNodeContents(span);
    if (isHighlightEnable) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Lấy vị trí của container
      const containerRect = containerRef.current.getBoundingClientRect();
      
      if (isLeft) {
        setMenuPosition({
          top: (e.clientY - containerRect.top - 2) + 'px',
          left: (e.clientX - containerRect.left + 5) + 'px'
        });
      } else {
        setMenuPosition({
          top: (e.clientY - containerRect.top + 2) + 'px',
          left: (e.clientX - containerRect.left - 50) + 'px'
        });
      }
    }
  }, [isLeft, isHighlightEnable]);


  const handleRemoveHighlight = () => {
    const selection = window.getSelection();
    if (!selection.toString()) return; // Không làm gì nếu không có vùng chọn

    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      // Lấy tất cả các node nằm trong vùng chọn
      const nodesInRange = [];
      const walkNodes = (node) => {
        if (range.intersectsNode(node)) {
          if (node.nodeType === Node.TEXT_NODE && node.textContent.trim()) {
            nodesInRange.push(node);
          } else {
            for (let child of node.childNodes) {
              walkNodes(child);
            }
          }
        }
      };

      walkNodes(range.commonAncestorContainer);

      // Xóa highlight (thẻ <span>) khỏi từng node trong vùng chọn
      nodesInRange.forEach((node) => {
        const parent = node.parentNode;

        if (parent.tagName === "SPAN" && parent.classList.contains("highlighted")) {
          const grandParent = parent.parentNode;

          // Di chuyển tất cả nội dung con của <span> ra ngoài
          while (parent.firstChild) {
            grandParent.insertBefore(parent.firstChild, parent);
          }

          // Xóa <span>
          grandParent.removeChild(parent);
        }
      });

      // Reset vùng chọn và menu
      selection.removeAllRanges();
      setMenuPosition(null);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const spans = container.querySelectorAll(".highlighted");
    spans.forEach((span) => {
      span.addEventListener("click", handleClickHighlighted);
    });
    // Cleanup để xóa sự kiện khi component unmount hoặc khi `text` thay đổi
    return () => {
      spans.forEach((span) => {
        span.removeEventListener("click", handleClickHighlighted);
      });
    };
  }, [text, isHighlightEnable, handleClickHighlighted]);
  return (
    <div className="highlight-container" onMouseUp={handleMouseUp} ref={containerRef}>
      <div className="text-start mt-2" id={`count-scope${isLeft ? "-left" : "-right"}`} dangerouslySetInnerHTML={{ __html: text }}></div>
      {menuPosition && (
        <div
          className="highlight-menu"
          style={{
            position: "absolute",
            top: `${menuPosition.top}`,
            left: `${menuPosition.left}`,
          }}
        >
          {selectedHighlight === null ? (
            <>
              <button className="highlight-menu-btn yellow" onClick={() => handleHighlight("yellow")}>
                <h6>""</h6>
              </button>
              <button className="highlight-menu-btn pink" onClick={() => handleHighlight("pink")}>
                <h6>""</h6>
              </button>
              <button className="highlight-menu-btn cyan" onClick={() => handleHighlight("cyan")}>
                <h6>""</h6>
              </button>
              <button className="highlight-menu-btn delete-highlight-btn" onClick={() => handleRemoveHighlight()}>
                <h6><i className="bi bi-trash"></i></h6>
              </button>
            </>
          ) : (
            <button onClick={handleRemoveHighlight}>Xóa Highlight</button>
          )}
        </div>
      )}
    </div >
  );
};

export default Highlight;