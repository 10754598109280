import { Modal, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { fetchPartnersAPI } from '../../apis';
import Search from 'antd/es/transfer/search';


const PartnerManagement = () => {
    const [dataSource, setDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);

    const handleAddPartner = () => {
        setSelectedPartner(null);
        setIsModalOpen(true);
    }

    // Hàm xử lý khi click vào nút xem chi tiết
    const handleViewDetail = (partner) => {
        console.log('Partner information:', partner);
        // Thêm code xử lý xem chi tiết ở đây
    };

    const handleSearch = (value) => {
        console.log('Search value:', value);
        // Thêm code xử lý tìm kiếm ở đây
    };


    // Lấy dữ liệu sinh viên từ API
    const fetchPartnerList = async () => {
        const response = await fetchPartnersAPI();
        setDataSource(response.result);
        setColumns(tableColumns);
    }

    // Gọi API khi component được tạo
    useEffect(() => {
        fetchPartnerList();
    }, []);

    // Định nghĩa cấu trúc các cột
    const tableColumns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Email', dataIndex: 'contactEmail', key: 'email' },
        { title: 'Phone', dataIndex: 'contactPhone', key: 'phone' },
        {
            title: 'Action',
            key: 'action',
            render: (student) => (
                <button
                    className='btn btn-dark btn-sm'
                    onClick={() => handleViewDetail(student)}
                >
                    View Detail
                </button>
            )
        }
    ];

    return (
        <div className='container'>
            <h1>Partner Management</h1>
            <div className='row'>
                <div className='col-10'>
                    <Search
                        placeholder="Search by name"
                    onSearch={handleSearch}
                    />
                </div>
                <div className='col-2'>
                    <button type='primary' className='btn btn-dark btn-sm' onClick={handleAddPartner}>
                        Add New Partner
                    </button>
                </div>
            </div>

            <Table
                dataSource={dataSource}
                columns={columns}
            />
            <Modal
                title="Add Partner"
                open={isModalOpen}
                destroyOnClose
                onCancel={() => setIsModalOpen(false)}
            >
                
            </Modal>
        </div>
    );
};

export default PartnerManagement;
