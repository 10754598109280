import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import Desmos from "desmos";
import "./DesmosComponent.css";
import Draggable from "react-draggable";
import { ResizableBox } from 'react-resizable';
import "react-resizable/css/styles.css";
const DesmosComponent = ({ visible, setVisible }) => {
  const desmosRef = useRef(null); // Tham chiếu DOM nơi Desmos sẽ render
  const calculatorRef = useRef(null); // Tham chiếu đối tượng Desmos
  const [dimensions, setDimensions] = useState({ width: 450, height: 600 }); // Thêm state cho kích thước
  const [isDraggable, setIsDraggable] = useState(false);
  // Khởi tạo Desmos khi Modal hiển thị
  useEffect(() => {
    if (visible && desmosRef.current) {
      calculatorRef.current = Desmos.GraphingCalculator(desmosRef.current);
    }

    // Cleanup khi Modal đóng
    return () => {
      if (calculatorRef.current) {
        calculatorRef.current.destroy();
        calculatorRef.current = null;
      }
    };
  }, [visible]);

  return (
    <>
      {/* Modal có khả năng kéo thả */}
      <Modal
        title={
          <div
            style={{ cursor: "move" }}
            onMouseOver={() => setIsDraggable(true)} // Bật kéo thả
            onMouseOut={() => setIsDraggable(false)} // Tắt kéo thả
          >
            Calculator
          </div>
        }
        
        centered={true}
        maskClosable={false}
        mask={false}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        
        wrapClassName="desmos-modal"
        style={{ position: 'absolute' }}
        width={dimensions.width}
        modalRender={(modal) => (
          <Draggable disabled={!isDraggable}>
            <div>
              <ResizableBox
                width={dimensions.width}
                height={dimensions.height}
                onResize={(e, { size }) => {
                  setDimensions({ width: size.width, height: size.height });
                }}
                minConstraints={[300, 300]}
                maxConstraints={[1000, 1000]}
              >
                {modal}
              </ResizableBox>
            </div>
          </Draggable>
        )}
      >
        {/* Vùng render Desmos */}
        <div
          ref={desmosRef}
          style={{
            width: "100%",
            height: dimensions.height - 55,
            border: "1px solid #ccc",
            background: "#fff",
          }}
        ></div>
      </Modal>

    </>
  );
};

export default DesmosComponent;
